<template>
    <section>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="goToEditPostContent()">Modifier le contenu de l'article</button>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <!-- <Content v-if="ulid" :ulid="ulid" :editable="false" page_name="blog" /> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Commentaires</h4>
                        <div class="profile-feed">
                            <div v-if="postcomments.length==0">Il n'y a pas de commentaire</div>
                            <div class="d-flex align-items-start profile-feed-item" v-for="postcomment in postcomments" :key="postcomment.id">
                                <!-- <img src="../../../../assets/images/faces/face13.jpg" alt="profile" class="img-sm rounded-circle"/> -->
                                <div class="ml-4">
                                <h6>
                                   {{postcomment.user.lastname}} {{postcomment.user.firstname}}
                                    <small class="ml-4 text-muted"><i class="mdi mdi-clock mr-1"></i>10 hours</small>
                                </h6>
                                <p>
                                    {{postcomment.comment}}
                                </p>
                                <p class="small text-muted mt-2 mb-0">
                                    <span>
                                    <i class="mdi mdi-star mr-1"></i>4
                                    </span>
                                    <span class="ml-2">
                                    <i class="mdi mdi-comment mr-1"></i>11
                                    </span>
                                    <span class="ml-2">
                                    <i class="mdi mdi-reply"></i>
                                    </span>
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
// import Content from '../preview/page-preview-d.vue'
export default {
    name:"postDetails",
    components:{
        // Content
    },
    data(){
        return{
            // postcomments:[1,2,3],
            ulid:"",
            postcomments:[]
        }
    },
    methods:{
        goToEditPostContent(){
            this.$router.push({
                name:"page-preview",
                params:{
                    ulid:this.ulid,
                    page:"blog"
                }
            })
        },
        getPosts(ulid){
            axios.get(`posts/${ulid}`,{
                headers:{
                    "X-AUTH-TOKEN":localStorage.getItem('token')
                }
            })
            .then(resGetPosts => {
                console.log(resGetPosts);
                this.postcomments = resGetPosts.data.postcomments
            })
            .catch(errGetPosts => console.log(errGetPosts))
        }
    },
    mounted(){
        this.ulid = this.$route.params.ulid,
        this.getPosts(this.ulid)
    }
}
</script>